<template lang="pug">
  v-container
    v-data-table(
      :items="customerApplicationList"
      :headers="headers"
      :loading="customerApplicationGetting"
      dense
    )
      template(v-slot:item="{ item }")
        tr
          td {{ item.name }}
          td {{ item.category.description || null }}
          td {{ item.email }}
          td {{ item.date_of_birth.dateFormat({}) }}
          td {{ item.contact_no1 }}
          td {{ item.contact_no2 }}
          td {{ item.character_ref }}
          td
            v-btn(
              color="light-green darken-1"
              dark
              small
              @click="showCustomerApplication(item)"
            )
              span Show
</template>
<script>
import customerApplicationRepository from '@/repositories/customer-application.repository'
import VueGetApi from '@/libs/classes/VueGetApi.class'
import { getVars } from '@/libs/api-helper.extra'

const fields = [
  'id',
  'customer_application_category_id',
  'name',
  'email',
  'date_of_birth',
  'brgy',
  'municipality',
  'province',
  'no_of_heads',
  'contact_no1',
  'contact_no2',
  'character_ref',
  'hog_pen_length',
  'hog_pen_width',
  'hog_pen_division',
]

const tableVars = () => ({
  headers: [
    { text: 'Name', value: 'name' },
    { text: 'Category', value: 'customer_application_category_id' },
    { text: 'Email', value: 'email' },
    { text: 'Date Of Birth', value: 'date_of_birth' },
    { text: 'Contact No. 1', value: 'contact_no1' },
    { text: 'Contact No. 2', value: 'contact_no2' },
    { text: 'Character Ref.', value: 'character_ref' },
    { text: 'Config', sortable: false },
  ],
})

export default {
  name: 'CustomerApplicationTable',
  data () {
    return {
      ...tableVars(),
      ...getVars('customer-application'),
    }
  },
  created () {
    this.initWebsocketEvents()
    this.getCustomerApplications()
  },
  methods: {
    getCustomerApplications () {
      if (this.customerApplicationGetting) return
      const variableNames = this.getApiGetRequestVariableNames()
      const apiGetRequest = new VueGetApi(this, variableNames)
      const repository = customerApplicationRepository.index()
      apiGetRequest.fetch(repository)
    },
    getApiGetRequestVariableNames () {
      return {
        loading: 'customerApplicationGetting',
        list: 'customerApplicationList',
        error: 'customerApplicationGetErrors',
      }
    },
    showCustomerApplication (data) {
      this.$emit('show-application', data)
    },
    initWebsocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.DBStoreEvent)
    },
    DBStoreEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'CustomerApplication') {
        this.customerApplicationDBStoreEvent(data)
      }
    },
    customerApplicationDBStoreEvent (data) {
      const list = this.customerApplicationList.map(item => Object.assign({ ...item }))
      list.unshift(data)
      this.customerApplicationList = list
    },
  },
}
</script>